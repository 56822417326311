class String {
  static Get(data) {
    if (data) {
      return data.trim();
    } else {
      return "";
    }
  }

  static Capitalise(value) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
  }

  // Capitalize the first letter of each word
  static CapitalizeWords(value) {
    if (value) {
      return value
        .replace(/[^a-zA-Z0-9 ]/g, " ")
        .split(" ")
        .map((data) => this.Capitalise(data) + " ")
        .toString()
        .split(",")
        .join(" ");
    }
  }

  // Trim the value
  static Trim(value) {
    return value ? value.trim() : "";
  }
}

export default String;
