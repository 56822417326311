/**
 * Get  Name
 *
 * @param firstName
 * @param lastName
 * @returns {*}
 */
export function GetDisplayName(firstName, lastName , email ) {
  try {
    let name = [];

    if (firstName) {
      name.push(firstName);
    }

    if (lastName) {
      name.push(lastName);
    }
    if (!firstName && !lastName) {
      name.push(email);
    }

    if (name) {
      return name.join(" ");
    }

    return "";
  } catch (error) {
    console.log(error);
  }
}
