import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";


const propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  url: PropTypes.string,
  state: PropTypes.string
};
class LinkTo extends React.Component {

  render(){
    const {
      onClick , 
      className,
      style,
      url,
      state,
      text,
      src,
      alt,
      iconClassName,
      spanClassName,
      label,
      icon,
      onMouseEnter,
      onMouseLeave,
    } = this.props;

    return (
      <Link
        id= {text || label }
        onClick={onClick}
        className={className ? className : "linkto"}
        to={url ? url : ""}
        onMouseEnter= {onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={style}
        state={state}
      >
        {icon}
        <img 
          src={src}
          alt={alt}
          className={iconClassName} /> 
        <span className={spanClassName}>{label}</span>
        {text} 
      </Link>
    );
  }
}

LinkTo.propTypes = propTypes;

export default LinkTo;
