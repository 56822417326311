/**
 * Get Cookie
 *
 * @param cname
 * @returns {string}
 */
function get(cname) {
  try {
    const nameEQ = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return "";
  } catch (err) {
    console.log(err);
  }
}

/**
 * Set Cookie
 *
 * @param cookieName
 * @param cookieValue
 * @param days
 */
function set(cookieName, cookieValue, days = 1) {
  try {
    let date, expires;
    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = cookieName + "=" + cookieValue + expires + "; path=/";
  } catch (err) {
    console.log(err);
  }
}

/**
 * Clear Cookie
 *
 * @param name
 */
function clear(name) {
  Cookie.set(name, "", -24);
}

module.exports = Cookie = {
  get,
  set,
  clear,
  clearAllCookies,
  deleteCookie,
};

/**
 * Clear All  Cookies
 *
 * @param name
 */
function clearAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++)
    deleteCookie(cookies[i].split("=")[0]);
}

function deleteCookie(name) {
  Cookie.set(name, "", -1);
}
