import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Navbar,
} from "reactstrap";

// assets
import logo from "../../assets/img/bowtie-logo.png";
import avatar from "../../assets/img/avatar.png";
import logoutIcon from "../../assets/img/logout.png";

//Common
import * as Cookie from "../../lib/Cookie";
//Component
import AvatarCard from "../AvatarCard";
import Logo from "../Logo";

//Constant
import MobileHamburgerMenu from "../MobileHamburgerMenu";
import { COOKIE_USER_EMAIL } from "../../constants/Cookie";

//components
import LinkTo from "../LinkTo";

//Styles
import "./Style.scss";

const Header = ({ showUserAvatar, className, showMobileHamburgerMenu }) => {
  // State
  const [isOpen, setIsOpen] = useState(false);
  // Navigate
  const navigate = useNavigate();

  // Toggle for dropdown
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // Logout function
  const logout = () => {
    Cookie.clearAllCookies();
    navigate("/");
  };

  // To get user name from cookie
  const userName = Cookie.get(COOKIE_USER_EMAIL).split("@")[0];
  return (
    <>
      <Navbar
        className={`navbar navbar-expand-lg bg-color-primary text-white ${className}`}>
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <div  className="col-6 col-lg-6 col-md-6 col-xs-6">
              <div className="d-flex align-items-center">
                {showMobileHamburgerMenu && 
                   <MobileHamburgerMenu />
                }
                <Logo src={logo} link={"#"} altText={"logo"} label={"Bowtie"} className="logo" />
              </div>
            </div>

            {/* User avatar section */}
            {showUserAvatar ? (
              <div className="col-6 col-lg-6 col-md-6 col-xs-6" >
                <div className="float-end">
                  <Dropdown direction="up" isOpen={isOpen} toggle={toggle}>
                    <DropdownToggle color="#F8F9FA" outline="none">
                      <AvatarCard
                        color="#FFFFFF"
                        fontBolded
                        firstName={userName}
                        headerClassName={"text-capitalize"}
                        hideProfileName
                      />
                      <DropdownMenu
                        className="p-3 mb-1 text-center border-radius"
                        right
                      >
                        <div className="d-flex flex-column text-start position-relative mb-2 pt-1">
                          <DropdownItem className="mb-3 d-flex gap-3 align-items-center justify-content-start">
                            <div
                              to="profile"
                              className="d-flex gap-3 text-dark text-decoration-none text-capitalize" >
                              <img src={avatar} alt="profile avatar" />
                              {userName}
                            </div>
                          </DropdownItem>

                        </div>

                        <div className="text-start" onClick={logout} >
                          <DropdownItem>
                            <LinkTo
                              url="/"
                              onClick={logout}
                              className="text-dark text-decoration-none"
                             
                              text = "Log Out"
                          
                              src={logoutIcon}
                              alt="logout"
                              iconClassName="icon"
                            />
                            
                          
                          </DropdownItem>
                        </div>
                      </DropdownMenu>
                    </DropdownToggle>
                  </Dropdown>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
