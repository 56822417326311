import React from "react";
import PropTypes from "prop-types";

// Components
import Avatar from "../Avatar";

// Assets
import { LeftArrowIcon } from "../../assets/icons";
import { GetDisplayName } from "../../helpers/Contact";


// Style
import "./Style.scss";
import LinkTo from "../LinkTo";

class AvatarCard extends React.Component {
  render() {
    const {
      title,
      firstName,
      lastName,
      email,
      fontBolded,
      showBackArrow,
      backUrl,
      color,
      activeTab,
      headerClassName,
      handleOnClick,
      hideProfileName,
    } = this.props;

    // Defining the names as a constant
    const names = [];
    // Appending the first name value into the names
    if (firstName) {
      names.push(firstName);
    }
    // Appending the last name value into the names
    if (lastName) {
      names.push(lastName);
    }

    // Defining the name to join both the first and the last name values.
    const name = names.join(" ");
    // TO define the text color
    const textColor = color ? color : "";
    return (
      // div to make the contents in single line and center
      <div className="d-flex align-items-center">
        {/* div to specify the padding for the Backarrow */}
        <div className="pe-3 link"  onClick={handleOnClick && handleOnClick}>
          {showBackArrow && (
            <LinkTo
              className="link-width"
              url={backUrl}     
              state={{ activeTab: activeTab }}
              icon={ <LeftArrowIcon />}
            />
          )}
        </div>
        {/* div to specify the padding for avatar  */}
        <div className = "avatar-padding-right" >
          <Avatar {...this.props} />
        </div>

        {/* displays either of the name, title, email */}
        {(name || title || email) && (
          <div>
            {/* displaying name */}
            {name && (
              <div
                className={`${fontBolded ? "fw-bold" : ""} ${headerClassName ? headerClassName : ""} ${hideProfileName ? "profile-name" : ""} `}
                style={{
                  margin: "0 0 2px",
                  color: `${textColor}`,
                }}>
                {GetDisplayName(firstName , lastName)}
              </div>
            )}
            {/* displaying title / email */}
            {title ? (
              <p
                style={{
                  margin: 0,
                  color: `${textColor} !important`,
                }}>
                {title}
              </p>
            ) : email ? (
              <p
                style={{
                  textDecorationLine: "underline",
                  margin: 0,
                  color: `${textColor}`,
                }}>
                {email}
              </p>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

AvatarCard.propTypes = {
  url: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  defaultUrl: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  square: PropTypes.bool,
  customSize: PropTypes.number,
};

export default AvatarCard;
